<template>
  <div class="register-step2">
    <top-bar back backroute="auth" is-auth :img="logo" />
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="title"><t name="2.6" /></div>
      <div class="content"><t name="3.25" /></div>
      <div class="wrap">
        <div v-for="item in categories" :key="item.id" class="item-wrap"
             :class="{'selected': selected.includes(item.id)}" @click="selectCategory(item)">
          <div class="content-wrapper">
            <div class="img" v-html="item.image"></div>
            <div class="title">{{ item.name }}</div>
          </div>
        </div>
        <n-button class="button-next" color="yellow" wide @click="next"><t name="3.13" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageFinishRegister',
  data() {
    return {
      logo, img,
      categories: [],
      selected: [],
      additional: 0,
    }
  },
  watch: {},
  created() {
    this.loadCategories()
    if ($n.getLocalVal('regData').role === 1) {
      this.$router.push({ name: 'register.enterData', })
    }
  },
  methods: {
    next() {
      if (this.selected.length !== 0) {
        const data = $n.getLocalVal('regData')
        data.categories = this.selected
        this.$n.saveLocal('regData', data)
        this.$router.push({ name: 'register.enterData', })
      }
    },
    selectCategory(item) {
      if (this.selected.includes(item.id)) {
        if (this.selected.length > 1) {
          this.selected = $n.filter(this.selected, (e) => {
            if (e !== item.id) {
              return e
            }
          })
        }
      } else {
        if (this.selected.length < 3) {
          this.selected.push(item.id)
        }
      }
    },
    loadCategories() {
      this.$var('load', true)
      $api.categories.get().with('children').all().then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2 {
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .button-next {
    margin-top: 25px;
  }
  .wrap {
    .item-wrap {
      display: flex;
      align-items: center;
      margin-top: 25px;
      border-radius: 18px;
      padding: 10px;
      border: 5px solid #c7c5c5;
      &.selected {
        border: 5px solid var(--primary);
      }
    }
    .content-wrapper {
      display: flex;
      align-items: center;
      .title {
        margin-top: 0;
        font-size: 18px;
        margin-bottom: 0;
        text-align: left;
      }
      .img {
        width: 52px;
        height: 52px;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .title {
    margin-top: 70px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .content {
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input {
    margin-bottom: 16px;
  }

  .n-button {
    position: relative;

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
